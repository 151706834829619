import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import BidsCarousel from "../carousel/bidsCarousel";

const Bids = ({ classes = "pt-10 pb-24", bgWhite }) => {
  return (
    <section id="works" className={classes}>
      {/* <!-- Hot Bids --> */}
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
      )}
      <div className="max-h-full mx-auto pt-24 pb-24 bg-light-base dark:bg-jacarta-800">
        {/* <div className="container"> */}
        <HeadLine
          text="Previous Works"
          image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png"
          classes="font-display text-jacarta-700 mb-4 text-center text-3xl dark:text-white"
        />
        <p className="text-lg pb-16 dark:text-jacarta-300 max-w-3xl mx-auto text-center">
          Take a look at my Works, and you will find a range of successful
          projects in various industries, from e-commerce and SaaS to
          Educational Paltform and more.
        </p>
        <div className="relative container ">
          {/* <!-- Slider --> */}
          <BidsCarousel />
        </div>
      </div>
      {/* <!-- end hot bids --> */}
    </section>
  );
};

export default Bids;
