import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { bidsData } from "../../data/bids_data";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { bidsModalShow } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import Likes from "../likes";
import VideoBlock from "../dao/VideoBlock";

const BidsCarousel = () => {
  const dispatch = useDispatch();
  const handleclick = () => {
    console.log("clicked on ");
  };
  return (
    <>
      <Swiper
        // modules={[Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        modules={[Navigation, Pagination, Scrollbar]}
        // spaceBetween={30}
        // slidesPerView="auto"
        // loop={true}
        // onAutoplay={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 1,
          },
          1000: {
            slidesPerView: 1,
          },
          1100: {
            slidesPerView: 1,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {bidsData.map((item) => {
          const { id, image, title, desc1, desc2, tech, link, linkTitle } =
            item;
          const itemLink = image
            .split("/")
            .slice(-1)
            .toString()
            .replace(".jpg", "");
          return (
            <SwiperSlide className="" key={id}>
              <section className="bg-light-base dark:bg-jacarta-800">
                <div className="container">
                  <div className="lg:flex lg:flex-nowrap">
                    <div className="lg:w-[59%]">
                      <figure className="relative mb-8 overflow-hidden rounded-3xl before:absolute before:inset-0 ">
                        <img
                          // src="/images/dao/video_cover_dao.jpg"
                          src={image}
                          className="w-full"
                          alt=""
                        />
                      </figure>
                    </div>
                    {/* End VideoBlock */}

                    <div className="lg:w-[45%] lg:pl-16">
                      <h2 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                        {title}
                      </h2>
                      <p className="dark:text-jacarta-300 mb-8 text-lg leading-normal">
                        {desc1}
                      </p>
                      <p className="dark:text-jacarta-300 mb-10">{desc2}</p>
                      <div className="flex space-x-4 sm:space-x-10">
                        <div className="flex space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="fill-accent h-8 w-8 shrink-0"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                          </svg>
                          <div>
                            <span className="font-display text-jacarta-700 block text-xl dark:text-white">
                              Technologies Used
                            </span>
                            <span className="dark:text-jacarta-300 text-jacarta-700">
                              {tech}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-4  pt-5 items-center">
                        <svg
                          width={"24"}
                          height={"24"}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          id="Link"
                        >
                          <g
                            data-name="Layer 2"
                            fill="#5747d7"
                            className="color000000 svgShape"
                          >
                            <g
                              data-name="link-2"
                              fill="#5747d7"
                              className="color000000 svgShape"
                            >
                              <path
                                d="m13.29 9.29-4 4a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4-4a1 1 0 0 0-1.42-1.42z"
                                fill="#5747d7"
                                className="color000000 svgShape"
                              ></path>
                              <path
                                d="M12.28 17.4 11 18.67a4.2 4.2 0 0 1-5.58.4 4 4 0 0 1-.27-5.93l1.42-1.43a1 1 0 0 0 0-1.42 1 1 0 0 0-1.42 0l-1.27 1.28a6.15 6.15 0 0 0-.67 8.07 6.06 6.06 0 0 0 9.07.6l1.42-1.42a1 1 0 0 0-1.42-1.42zm7.38-14.18a6.18 6.18 0 0 0-8.13.68L10.45 5a1.09 1.09 0 0 0-.17 1.61 1 1 0 0 0 1.42 0L13 5.3a4.17 4.17 0 0 1 5.57-.4 4 4 0 0 1 .27 5.95l-1.42 1.43a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l1.42-1.42a6.06 6.06 0 0 0-.6-9.06z"
                                fill="#5747d7"
                                className="color000000 svgShape"
                              ></path>
                            </g>
                          </g>
                        </svg>
                        <div>
                          <a href={link} target="_blank" rel="noreferrer">
                            <p className="underline cursor-pointer">
                              {linkTitle}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
