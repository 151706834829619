// import '/images/item_1.jpg' from '/images/item_1.jpg';

const bidsData = [
  {
    id: 1,
    image: "/images/dao/video_cover_dao.jpg",
    title: "Fetan Education Website",
    link: "https://fetaneducation.com/",
    linkTitle: "fetaneducation",
    tech: "ReactJs, Typescript, Firebase, Tailwind, Redux, and more.",
    desc1:
      "Fetan Education is the ultimate online platform for students in Ethiopia who want to ace their exams and achieve their academic dreams. Whether you are preparing for university entrance, freshman, remedial and more ..Fetan has you covered with the best and most relevant content, quizzes, and practice tests",
    desc2:
      "Fetan supports multiple languages, devices, and platforms to ensure that you can access the website anytime, anywhere, and on any device. With Fetan, you can be confident that you are getting the best preparation for your exams and your future. 🚀",
  },
  {
    id: 2,
    image: "/images/dao/video_cover_dao1.jpg",
    title: "ACEWM - African Center of Excellence for Water Management",
    linkTitle: "ACEWM",
    link: "https://water-project-home.vercel.app/",
    tech: "Next Js, Tailwind, Typescript ..",
    desc1:
      "I spearheaded the redesign and enhancement of the ACEWM website, ACEWM is a hub dedicated to advancing education, research, and initiatives in water management across Africa, fostering excellence in the sustainable use of water resources for the region's development. ",
    desc2:
      "Employed Next.js and React for enhanced performance and dynamic functionality.Implemented Tailwind CSS to streamline responsive design and elevate the site's visual appeal. Transformed the user interface for intuitive navigation, ensuring an enriched user experience.",
  },
  {
    id: 2,
    image: "/images/dao/video_cover_dao2.png",
    title: "Telebirr App Landing",
    linkTitle: "telebirr",
    link: "https://telebirr-webiste.netlify.app/",
    tech: "React JS, Css, ..",
    desc1:
      "I have created a website for telebirr app landing page as a Website Challenge. ",
    desc2:
      "Telebirr is the leading online payment app in Ethiopia that lets you manage your money with just your mobile number. Whether you want to send or receive money, buy airtime or packages, pay bills or tickets, donate or fundraise, or shop online or offline, Telebirr has you covered with its fast, reliable, and convenient service. You can also access telebirr’s financial services such as saving, microcredit, and overdraft, and receive international remittance from your loved ones abroad.",
  },
  {
    id: 3,
    image: "/images/dao/video_cover_dao3.jpg",
    title: "ACEWM Admin",
    desc1:
      "By leveraging insights from our network of industry insiders, you’ll know exactly when to buy to maximize profit, and exactly when to sell to avoid painful losses.",
    desc2:
      "By leveraging insights from our network of industry insiders, you’ll know exactly when to buy to maximize profit, and exactly when to sell to avoid painful losses.",
  },
  {
    id: 4,
    image: "/images/dao/video_cover_dao4.jpg",
    title: "Black Storm Ecommerce",
    linkTitle: "blackstorm eccomerce",
    link: "https://blackstorm-pc-ecom-2.vercel.app/",
    tech: "React JS, Redux, Css, SASS ..",
    desc1:
      "Blackstorm is the ultimate online store for PC gamers, offering you the best and most affordable PC gaming products, and a smooth and seamless online shopping experience.",
    desc2:
      "You can browse through our extensive and updated catalog of PC gaming products, featuring everything from top brands and manufacturers. You can also use our advanced search and filter features to find exactly what you are looking for, based on your preferences, budget, and specifications.",
  },
];

export { bidsData };
